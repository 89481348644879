import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
  },
  {
    path: "/bigScreen",
    name: "bigScreen",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/bigScreen.vue"),
    meta: {
      title: "大屏展示",
      requireAuth: true,
    },
  },
  {
    path: "/home",
    name: "Home",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Home.vue"),
    redirect: "/traffic",
    leaf: false,
    children: [
      //  问卷列表
      {
        path: "/traffic",
        name: "H5浏览量查看",
        component: () => import("../views/home/Traffic.vue"),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/streetList",
        name: "花园式街道自填信息表",
        component: () => import("../views/home/streetList.vue"),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/communityList",
        name: "花园式社区自填信息表",
        component: () => import("../views/home/CommunityList.vue"),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/map",
        name: "地图显示",
        component: () => import("../views/home/Map.vue"),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/adminList",
        name: "管理员列表",
        component: () => import("../views/home/AdminList.vue"),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/userList",
        name: "用户列表",
        component: () => import("../views/home/UserList.vue"),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/messageShow",
        name: "数据展示",
        component: () => import("../views/home/MessageShow.vue"),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/comprehensiveScore",
        name: "综合评分",
        component: () => import("../views/home/ComprehensiveScore.vue"),
        meta: {
          requireAuth: true,
        },
      },
    ],
  },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  let islogin = localStorage.getItem("id");
  islogin = Boolean(islogin);
  let realName = localStorage.getItem("realName");
  let isRealName = Boolean(realName);
  //判读超管
  // let canAssignRole = JSON.parse(localStorage.getItem("canAssignRole"));
  // let canAssignAreas = JSON.parse(localStorage.getItem("canAssignAreas"));
  // if (canAssignRole && canAssignAreas) {
  // } else {
  //   if (to.path == "/userList" || to.path == "/adminList") {
  //     next("/home");
  //   }
  // }
  let role = localStorage.getItem('assignedRoles') ? Array.from(JSON.parse(localStorage.getItem('assignedRoles')), item => item.key) : '';
  
  if(!role.includes('superadmin') && (role.includes('verifier') || role.includes('auditor')) && !(to.path == "/traffic" || to.path == "/bigScreen")) {
    if(role.includes('auditor') && to.path == "/map") {
      return next();
    }
    if(role.includes('rater') && to.path == "/comprehensiveScore") {
      return next();
    }
    next("/home");
  }

  if (to.path == "/login") {
    if (islogin) {
      next("/home");
    } else {
      next();
    }
  } else {
    if (to.meta.requireAuth && islogin && isRealName) {
      next();
    } else {
      next("/login");
    }
  }
});

export default router;
