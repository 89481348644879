/*
 * @Author: pengke 987501716@qq.com
 * @Date: 2022-05-09 23:38:41
 * @LastEditors: pengke 987501716@qq.com
 * @LastEditTime: 2022-08-17 21:37:45
 * @FilePath: \Questionnaire-survey-Bg\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./styles/global.less";
// import DonMessage from './globalJs/message';
import Rem from "./globalJs/rem";
// import $ from 'jquery';
import VueResource from "vue-resource";
import axios from "axios";
import moment from "moment";
import BaiduMap from "vue-baidu-map";
import echarts from "echarts";
import scroll from "vue-seamless-scroll";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.css";

import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import "./styles/iconfont.css"

Vue.filter("timeTransform", (input, string) => {
  return moment(input).format(string);
});
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(Rem);
Vue.use(VueResource);
Vue.prototype.$axios = axios;
Vue.prototype.$echarts = echarts;
Vue.prototype.$moment = moment;
Vue.use(VueAwesomeSwiper);
Vue.use(scroll);
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: "TsUo0SlXhZVAxKGWumibMLp4P9Lpg32K",
});

import { mixin } from './mixin'
Vue.mixin(mixin)

Vue.use(VueQuillEditor /* { default global options } */);
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
