export const mixin = {
    data() {
        return {
          //是否为审核员
          isAuditor: false,
          //是否为检验员
          isVerifier: false,
          //是否为管理员
          isAdmin: false,
          //是否为超管
          isSuperMan: false,
          //是否为评分员
          isRater: false,
          //地区
          areasOptions: [
              {
                value: "东城区",
                label: "东城区",
              },
              {
                value: "西城区",
                label: "西城区",
              },
              {
                value: "密云区",
                label: "密云区",
              },
              {
                value: "延庆区",
                label: "延庆区",
              },
              {
                value: "朝阳区",
                label: "朝阳区",
              },
              {
                value: "丰台区",
                label: "丰台区",
              },
              {
                value: "石景山区",
                label: "石景山区",
              },
              {
                value: "海淀区",
                label: "海淀区",
              },
              {
                value: "门头沟区",
                label: "门头沟区",
              },
              {
                value: "房山区",
                label: "房山区",
              },
              {
                value: "通州区",
                label: "通州区",
              },
              {
                value: "顺义区",
                label: "顺义区",
              },
              {
                value: "昌平区",
                label: "昌平区",
              },
              {
                value: "大兴区",
                label: "大兴区",
              },
              {
                value: "怀柔区",
                label: "怀柔区",
              },
              {
                value: "平谷区",
                label: "平谷区",
              },
          ],
          // 评分结构
          scoreStruc: {
            base: ['signageScore', 'violationScore'],
            greeningQuantity: ['plantScore', 'roofGreeningScore', 'greenAreaScore', 'verticalGreeningScore', 'oldTreeScore', 'plantCoverScore', 'quantityGuideScore'],
            greeningInfo: ['landscapeEffectScore', 'pestHazardsScore', 'treeGrowthScore', 'plantConstructionScore', 'managementPrecisionScore', 'plantConfigEffectScore', 'plantLackScore', 'supportLandscapeFacilitiesScore', 'landscapeArtStructuresScore'],
            importance: ['annualInvestmentUnitPriceScore', 'featureAndImportanceScore'],
          }
        }
    },
    methods: {
      exportNowPower(isSuperMan, isAdmin, isAuditor, isVerifier) {
          this.isSuperMan = isSuperMan;
          this.isAdmin = isAdmin;
          this.isAuditor = isAuditor;
          this.isVerifier = isVerifier;
      },
      //判断权限
      isPower() {
          let nowUserPower = Array.from(JSON.parse(localStorage.getItem('assignedRoles')), item => item.key);
          if(nowUserPower.length === 0) {
            this.$message.error('当前登录账号的权限有问题请重新登录！');
            return this.$router.push({ path: "/login" });
          }
          if(nowUserPower.includes('superadmin')) {
              this.exportNowPower(true, true, true, true)
          }else if(nowUserPower.includes('admin')) {
              this.exportNowPower(false, true, true, true)
          }else if(nowUserPower.includes('auditor') && nowUserPower.includes('verifier')) {
              this.exportNowPower(false, false, true, true)
          }else if(nowUserPower.includes('auditor') && !nowUserPower.includes('verifier')) {
              this.exportNowPower(false, false, true, false)
          }else if(nowUserPower.includes('verifier') && !nowUserPower.includes('auditor')) {
              this.exportNowPower(false, false, false, true)
          }
          if(nowUserPower.includes('rater')) {
            this.isRater = true;
          }
      },
      //处理时间
      handleDate(date, type=1, beforeLinkSymbol='-', afterLinkSymbol=':') {
          let resDate = new Date(date);
          //年
          let year = resDate.getFullYear();
          //月
          let month = this.repair(Number(resDate.getMonth())+1);
          //日
          let day = this.repair(resDate.getDate());
          //时
          let hours = this.repair(resDate.getHours());
          //分
          let minutes = this.repair(resDate.getMinutes());
          //秒
          let seconds = this.repair(resDate.getSeconds());
          //毫秒
          let milliseconds = this.repair(resDate.getMilliseconds());
      
          if(type == 1){
              return year+beforeLinkSymbol+month+beforeLinkSymbol+day+' '+hours+afterLinkSymbol+minutes+afterLinkSymbol+seconds;
          }else if(type == 2){
              return year+beforeLinkSymbol+month+beforeLinkSymbol+day;
          }else if(type == 3){
              return hours+afterLinkSymbol+minutes+afterLinkSymbol+seconds;
          }else if(type == 4){
              return year+beforeLinkSymbol+month+beforeLinkSymbol+day+' '+hours+afterLinkSymbol+minutes+afterLinkSymbol+seconds+afterLinkSymbol+milliseconds;
          }
      },
      //时间补零
      repair(msg) {
          let res = msg.toString().length < 2 ? '0'+msg : msg;
          return res;
      },
      getTransMultiple(data, type, split = " ") {
        let res = data;
        if(type === 'string' && Array.isArray(data)) {
          if(data.length !== 0) {
            res = data.join(split)
          }
        }else if(type === 'array' && typeof data === 'string') {
          if(data) {
            res = data.trim().split(split)
          }
        }
        return res;
      },
      // 计算分数
      getMathScore(score) {
        let res = {
          allScore: '',
          baseScore: '',
          greeningQuantityScore: '',
          greeningInfoScore: '',
          importanceScore: '',
          base: {},
          greeningQuantity: {},
          greeningInfo: {},
          importance: {}
        };

        if(!score) {
          return {};
        }

        Object.keys(this.scoreStruc).forEach(key => {
          this.scoreStruc[key].forEach(item => {
            if(score[item] || score[item] === 0) {
              res[key][item] = score[item]
              res[`${key}Score`] = res[`${key}Score`] ? res[`${key}Score`] + score[item] : score[item]
            }
          })
        })

        res.allScore = score.totalScore;
        
        return res
      },
      // 返回表单效验结果
      async checkFormRes(refName) {
        let res = true;
        await this.$refs[refName].validate((valid) => {
          res = valid;
        })
        return res
      },
      showActionText(message, props) {
        return this.$notify({
          title: '提示',
          type: 'warning',
          position: 'bottom-right',
          ...props || {},
          message: message ? message : '无任何操作',
        });
      }
    }
}